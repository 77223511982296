//footer
.footer {
  width: 100%;
  background-color: #a7a7a7;
  color: #fff;
  a {
    text-decoration: none;
    color: inherit;
  }
  span, a {
    font-size: 13px;
  }
}
