.card {
  .card-border {
    margin: 0;
    width: 35%;
    border: solid 2px $light-orange;
    @media(max-width: 768px) {
      float: none !important;
      margin-left: 30%;
    }
  }
  .card-header {
    font-size: 25px;
    color: #ff9933;
    font-weight: bolder;
    line-height: 1;
    margin: 20px 0;
    text-align: center;
    @media(min-width: $screen-sm-min) {
      font-size: 30px;
      text-align: left;
    }
    @media(min-width: $screen-md-min) {

    }
    @media(min-width: $screen-lg-min) {
      font-size: 40px;
      margin: 50px 0;
    }
  }
  .card-text {
    margin: 0;
    padding: 0;
    text-align: justify;
    text-justify: inter-word;
    line-height: 1.25;
    font-size: 13px;
    color: $darkest;
    .card-text-1 {
      margin-bottom: 5px;
    }
    .card-text-2 {
      margin-bottom: 0;
    }
    @media(min-width: $screen-sm-min) {
      // text-align: left;
    }
  }
}
.card-bg-img-left {
  @extend .card;
  background: #fff;
  padding: 0;
  @media(min-width: $screen-sm-min) {

  }
  @media(min-width: $screen-md-min) {
    background: url(../img/card-img-left.jpg) no-repeat #fff;
    background-clip: border-box;
    background-size: auto 305px;
    min-height: 305px;
  }
  @media(min-width: $screen-lg-min) {
    background: url(../img/card-img-left.jpg) no-repeat #fff;
    background-clip: border-box;
    background-size: auto 380px;
  }
  .col-md-offset-4 {
    @media (min-width: 992px) {
        margin-left: 40.333333%;
    }
  }
  .col-md-offset-5 {
    @media (min-width: 992px) {
        margin-left: 52.666667%;
    }
  }
  .col-md-offset-8 {
    @media (min-width: 992px) {
      margin-left: 64.666667%;
    }
  }
  .col-md-4 {
    @media (min-width: 992px) {
      width: 35.333333%;
    }
  }
}
.card-bg-img-right {
  @extend .card;
  background: #fff;
  @media(min-width: $screen-sm-min) {

  }
  @media(min-width: $screen-md-min) {
    background: url(../img/card-img-right.jpg) 100% no-repeat #fff;
    min-height: 305px;
    background-clip: border-box;
    background-size: auto 305px;
  }
  @media(min-width: $screen-lg-min) {
    background: url(../img/card-img-right.jpg) 100% no-repeat #fff;
    min-height: 305px;
    background-clip: border-box;
    background-size: auto 305px;
  }
  .col-md-offset-1 {
    @media (min-width: 992px) {
        margin-left:7.93%;
    }
  }
  .col-md-offset-11 {
    @media (min-width: 992px) {
        margin-left: 92.1%;
    }
  }
  .col-md-offset-3 {
    @media (min-width: 992px) {
      margin-left: 24%;
    }
  }
  .col-md-9 {
    @media (min-width: 992px) {
      width: 76%;
    }
  }
}
.box {
  min-height: 427px;
  @media (min-width: $screen-sm-min) {
    // width: 76%;
  }
}
.card-overlay {
  .card-overlay-bg {
    min-height: 276px;
    margin: 0 auto;
    background-clip: border-box;
    background-size: auto 276px;
    text-align: center;
  }
  .card-overlay-text {
    color: transparent;
    border: solid 2px transparent;
    text-transform: uppercase;
    text-align: center;
    padding: 5px;
    font-size: 30px;
    font-weight: 600;
  }
  .card-overlay-blurb {
    color: transparent;
    text-align: left;
    // letter-spacing: -0.5px;
    margin-top: 10px;
    line-height: 1.25;
    word-break: keep-all !important;
  }
  //bg 1,2,3
  .card-overlay-bg1 {
    background: url(../img/tradition-bg.jpg) no-repeat 100% #fff;
    background-size: cover;
    @extend .card-overlay-bg;
  }
  .card-overlay-bg2 {
    background:
    url(../img/innovation-bg.jpg) no-repeat 46% #fff;
    background-size: cover;
    @extend .card-overlay-bg;
  }
  .card-overlay-bg3 {
    background:
    url(../img/kreativat-bg.jpg) no-repeat 40% #fff;
    background-size: cover;
    @extend .card-overlay-bg;
  }
}
.card-overlay:hover {
  .card-overlay-text {
    color: $lightest;
    border: solid 2px $lightest;
  }
  .card-overlay-blurb {
    color: $darkest;
    border: solid 2px $lightest;
    word-break: break-all;
    font-size: 16px;
  }
  .dark-overlay {
    transition: all 0.5s ease;
    background: #000;
    opacity: 0.55;
    height: 100%;
    padding: 111px 0;
  }
}
