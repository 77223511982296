//header
.content {
  min-height: 600px;
  @media(min-width: $screen-sm-min) {
    min-height: 800px;
  }
}
.slider-banner {
  top: 10px !important;
}
//page banner
.page-banner {
  width: 100%;
  padding-right: 0;
  padding-left: 0;
  position: relative;
  // top: 11px;
  .well {
    width: 100%;
    margin: 0 auto;
    position: absolute;
    padding: 19px 20px;
    // background: #333;
  }
  .woodwork-border {
    @media(min-width: $screen-md-min) {
      border-right: solid 20px $lightest;
      border-left: solid 20px $lightest;
    }
  }
}
.vid-bg {
  background: #f93;
  padding-right: 20px;
  padding-left: 20px;
}
.banner-bg {
  position: relative;;
  bottom: 50px;
  width: 345px;
  height: $img-banner-xs-height;
  transition: all 0.5s ease;
  left: -6px;
  @media(min-width: $screen-md-min) {
    left: 0;
    bottom: 50px;
  }
  .video {
    width: 100%;
    height: 100%;
    position: relative;
    bottom: 10px;
    @media(min-width: $screen-md-min) {
      bottom: 20px;
    }
  }
}
//main-content
.articles {
    width: 345px;
    padding: 0;
  @media(max-width: $screen-xs-max) {
    width: 288px;
  }
  @media(min-width: $screen-sm-min) {
    width: 600px;
  }
  @media(min-width: $screen-md-min) {
    width: 800px;
  }
  @media(min-width: $screen-lg-min) {
    width: 1020px;
  }
}
.treffer-img {
  img {
    width: 300px;
    height: auto;
  }
}
//contact map
.contact-map {
  width: 100%;
  height: 400px;
  position: relative;
  right: 0;
  top: 0;
  @media(min-width: $screen-sm-min) {
    height: 600px;
  }
  @media(min-width: $screen-lg-min) {
    height: 1152px;
  }
}
//footer
