.content-margin-50 {
  height: 20px;
  @media(min-width: $screen-sm-min) {
    height: 50px;
  }
}
.content-margin {
  height: 30px;
  @media(min-width: $screen-sm-min) {
    // height: 30px;
  }
  @media(min-width: $screen-md-min) {
    // height: 50px;
  }
}
.content-margin-5 {
  height: 5px;
}
.pad0 {
  padding: 0 !important;
}
.pad-right0 {
  padding-right: 0 !important;
}
.pad-left0 {
  padding-left: 0 !important;
}
.pad-top0 {
  padding-top: 0 !important;
}
.pad-down0 {
  padding-bottom: 0 !important;
}
//
.mar-right0 {
  margin-right: 0 !important;
}
.mar-left0 {
  margin-left: 0 !important;
}
.mar-top0 {
  margin-top: 0 !important;
}
.mar-down0 {
  margin-bottom: 0 !important;
}
.pad-top {
  height: $pad-xs;
}
.pad-bttm {
    height: $pad-xs;
}
