.sliders {
  .page-banner-img {
    bottom: 100px !important;
    overflow: visible !important;
    // padding: 0;
    @media(max-width: $screen-sm-max) {
      width: 100%;
    }
  }
}
.sliders-bg,.video-bg {
  background-color: $light-orange;
}
.sliders-bg {
  padding: 0 18px 18px;
}
.video-bg {
  padding: 0 18px 0 18px;
}
.carousel {
  background: $darkest;
  .carousel-inner {
    bottom: 20px;
  }
  .item img {
    @media(min-width: $screen-md-min) {
      width: 100%;
    }
  }

}
.carousel-indicators {
  bottom: -75px;
  left: 38%;
  width: 80%;
  transition: all 0.5s ease;
  @media(max-width: $screen-xs-max) {
    left: 28%;
    width: 100%;
  }
  @media(min-width: $screen-sm-min) {
    bottom: -90px;
    left: 31%;
    width: 100%;
  }
  li {
    width: 0;
  	height: 0;
    border-top: none;
  	border-left: 10px solid transparent;
  	border-right: 10px solid transparent;
  	border-bottom: 13px solid #ffebd6;
    border-radius: 0;
    background-color: transparent;
    margin: 0 10px;
    @media(min-width: $screen-sm-min) {
      border-left: 20px solid transparent;
    	border-right: 20px solid transparent;
    	border-bottom: 28px solid #ffebd6;
      margin: 0 30px;
    }
  }
}
.carousel-indicators .active {
  border-bottom: 13px solid $light-orange;
  margin: 0 10px;
  transition: all 0.5s ease;
  @media(min-width: $screen-sm-min) {
    border-bottom: 28px solid $light-orange;
  }
}
.carousel-caption {
  span {
    background-color: #000;
    color: #fff;
    border: 2px solid #fff;
    font-size: 6px;
    font-weight: 700;
    padding: 0px 10px;
    display: inline-block;
    text-transform: uppercase;
    letter-spacing: 0.1px;
    margin: 5px;
    @media(min-width: 500px) and (max-width: 767px) {
      font-size: 12px;
    }
    @media(min-width: $screen-sm-min) {
      font-size: 14px;
    }
    @media(min-width: $screen-md-min) {
      font-size: 24.98px;
    }
  }
}
//captions
.caption1 {
  bottom: 30%;
  text-align: left;
  left: 9%;
  @media screen and (min-width: $screen-sm-min) {
    bottom: 47%;
    text-align: left;
    left: 9%;
  }
}
.caption2 {
  bottom: 56%;
  text-align: left;
  left: 9%;
  @media screen and (min-width: 768px) {
    bottom: 56%;
    text-align: left;
    left: 9%;
  }
}
.caption3 {
  bottom: 80%;
  text-align: left;
  left: 9%;
  @media screen and (min-width: 768px) {
    bottom: 80%;
    text-align: left;
    left: 9%;
  }
}
.caption4 {
  bottom: 45%;
  text-align: right;
  right: 6%;
  @media screen and (min-width: $screen-sm-min) {
    bottom: 56%;
    text-align: right;
    right: 6%;
  }
}
.caption5 {
  bottom: 25%;
  text-align: right;
  right: 12%;
  @media screen and (min-width: $screen-sm-min) {
    bottom: 45%;
    text-align: right;
    right: 12%;
  }
}
