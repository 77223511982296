.navbar {
  box-shadow: none;
  margin-bottom: 0;
}
.navbar-default .navbar-nav {
  float: none !important;
  display: inline-block !important;
  .active {
    a, a:focus, a:hover {
      background-color: transparent;
      color: $light-orange;
      font-weight: 600;
    }
  }
  li a {
    font-weight: 600;
    @media(min-width: $screen-md-min) {
      font-size: 18px;
      padding-top: 40.5px;
      padding-left: 30px;
      padding-right: 30px;
    }
  }
  li a:hover, li a:focus {
    background-color: transparent;
    color: $light-orange;
  }
}
.navbar-brand {
  padding: 10px 15px;
  height: 80px;
  width: 150px;
  @media(min-width: $screen-sm-min) {
    margin-left: 0 !important;
  }
  @media(min-width: $screen-md-min) {
    width: 170px;
    padding:15px 0 0 !important;
  }
  img {
    height: auto;
    width: 100%;
    @media(min-width: $screen-md-min) {
      margin: 0 -60px;
      width: 300px;
    }
  }
}
